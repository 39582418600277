import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconUserCircle from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/user-circle.tsx";
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconUser from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/user.tsx";
import IconBuildingStore from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/building-store.tsx";
import IconFileSpreadsheet from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/file-spreadsheet.tsx";
import IconMapPin from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/map-pin.tsx";
import IconMail from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/mail.tsx";

export function AccountContent({ back }){

	return (
		<div class="titleb">
			<div class="d-flex align-items-center mt-2 mb-4">
				{back?
					<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={back}>
						<IconChevronLeft class="w-6 h-6" />
					</div>:
					<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
						<IconChevronLeft class="w-6 h-6" />
					</a>
				}
				<h1 class="d-flex align-items-center h3 mb-0">
					<img style="width:20px" class="d-inline-block me-1" src="icons/Personnages_Homme.svg" />
					<span class="ms-2 titleb">Le profil</span>
				</h1>
			</div>
			<div>
				<AccountShopContent />
			</div>
			<div>
				<AccountManagerContent />
			</div>
			<div>
				<AccountTagContent />
			</div>
		</div>
	);
}

export class AccountShopContent extends Component {
	static contextType = SessionContext;
	
	state = {
		displayMod: this.props.editOnly?'edit':'view',
		saving: false,
		shopname: null,
		address: null,
		postalCode: null,
		city: null
	};

	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;

		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'getShopContent',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					shopname: data.informations.shopname,
					address: data.informations.address,
					postalCode: data.informations.postalCode,
					city: data.informations.city
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	submit(){
		let that = this;

		let session = this.context;
		this.setState({saving: true});

		let info = {
			shopname: this.state.shopname,
			address: this.state.address,
			postalCode: this.state.postalCode,
			city: this.state.city
		};

		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'setShopContent',
				session: session,
				form: info
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);
				
				if(data.saved){
					if(that.props.validate){
						that.props.validate();
					}
					
				}

			},
			received: function(){
				that.setState({saving: false});
				console.log('done');
			}
		});

		if(!that.props.editOnly){
			that.setState({displayMod: 'view'});
		}
	}

	render() {
		let that = this;

		if(this.state.displayMod == 'edit'){
			
			return (
				<div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<h2 class="h5 d-flex align-items-center mb-0">
							<img class="d-inline-block w-6 h-6 me-2" src="icons/Parcours Client_Home.svg" /><span>L'établissement</span>
							</h2>
							<button type="button" class="btn arrowb text-white rounded-pill d-flex align-items-center btn-sm px-4"  onClick={()=>{that.submit();}}>Valider</button>
						</div>
						<div class="card shadow edit">
							<div class="card-body">
								<div class="my-1">Nom : <input type="text" class="finput" value={that.state.shopname} onChange={(ev)=>{that.setState({shopname: ev.target.value});}} /></div>
								<div class="my-1">Adresse : <input type="text" class="finput" value={that.state.address} onChange={(ev)=>{that.setState({address: ev.target.value});}} /></div>
								<div class="my-1">Code postal : <input type="text" class="finput" value={that.state.postalCode} onChange={(ev)=>{that.setState({postalCode: ev.target.value});}} /></div>
								<div class="my-1">Ville : <input type="text" class="finput" value={that.state.city} onChange={(ev)=>{that.setState({city: ev.target.value});}} /></div>
							</div>
						</div>
					</div>
				</div>
			);
			
		}else{
			
			return (
				<div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<h2 class="h5 d-flex align-items-center mb-0 titleb">
								<img class="d-inline-block w-6 h-6 me-2" src="icons/Parcours Client_Home.svg" />
								<span>L'établissement</span>
							</h2>
							<button type="button" class="btn btn-outline-b rounded-pill d-flex align-items-center btn-sm px-4"  onClick={()=>{that.setState({displayMod: 'edit'});}}>Modifier</button>
						</div>
						<div class="card shadow">
							<div class="card-body">
								<div class="h5">{that.state.shopname}</div>
								<div class="d-flex align-items-center">
									<img class="d-inline-block w-6 h-6 me-2" src="icons/Parcours Client_Geolocalisation.svg" />
									{that.state.address}, {that.state.postalCode} {that.state.city}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export class AccountManagerContent extends Component {
	static contextType = SessionContext;
	
	state = {
		displayMod: this.props.editOnly?'edit':'view',
		saving: false,
		firstname: null,
		lastname: null,
		email: null
	};

	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'getManagerContent',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					firstname: data.informations.firstname,
					lastname: data.informations.lastname,
					email: data.informations.email
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	submit(){
		let that = this;

		let session = this.context;
		this.setState({saving: true});

		let info = {
			firstname: this.state.firstname,
			lastname: this.state.lastname,
			email: this.state.email
		};

		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'setManagerContent',
				session: session,
				form: info
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);
				
				if(data.saved){
					if(that.props.validate){
						that.props.validate();
					}
					
				}

			},
			received: function(){
				that.setState({saving: false});
				console.log('done');
			}
		});

		if(!that.props.editOnly){
			that.setState({displayMod: 'view'});
		}
	}

	render() {
		let that = this;
		
		if(that.state.displayMod == 'edit'){

			return (
				<div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<h2 class="h5 d-flex align-items-center mb-0 titleb">
								<img class="d-inline-block w-6 h-6 me-2" src="icons/Personnages_Homme.svg" />
								<span>Le gérant</span>
							</h2>
							<button type="button" class="btn arrowb text-white rounded-pill d-flex align-items-center btn-sm px-4"  onClick={()=>{that.submit();}}>Valider</button>
						</div>
						<div class="card shadow edit">
							<div class="card-body">
								<div class="my-1">Prénom : <input type="text" class="finput" value={that.state.firstname} onChange={(ev)=>{that.setState({firstname: ev.target.value});}} /></div>
								<div class="my-1">Nom : <input type="text" class="finput" value={that.state.lastname} onChange={(ev)=>{that.setState({lastname: ev.target.value});}} /></div>
								<div class="my-1">Email : <input type="email" class="finput" value={that.state.email} onChange={(ev)=>{that.setState({email:ev.target.value});}} /></div>
							</div>
						</div>
					</div>
				</div>
			);
			
		}else{
			
			return (
				<div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<h2 class="h5 d-flex align-items-center mb-0 titleb">
							<	img class="d-inline-block w-6 h-6 me-2" src="icons/Personnages_Homme.svg" />
								<span>Le gérant</span>
							</h2>
							<button type="button" class="btn btn-outline-b rounded-pill d-flex align-items-center btn-sm px-4"  onClick={()=>{that.setState({displayMod: 'edit'});}}>Modifier</button>
						</div>
						<div class="card shadow">
							<div class="card-body">
								<div class="h5">{that.state.firstname} {that.state.lastname}</div>
								<div class="d-flex align-items-center"><img class="d-inline-block w-6 h-6 me-2" src="icons/Parcours Client_Mail.svg" />{that.state.email}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export class AccountTagContent extends Component {
	static contextType = SessionContext;
	
	state = {
		displayMod: this.props.editOnly?'edit':'view',
		saving: false,
		list: []
	};

	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'getKeywordsContent',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					list: data.list
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	submit(){
		let that = this;

		let session = this.context;
		that.setState({saving: true});

		let list = {actives: [], inactives: []};

		for(let category of that.state.list){

			for(let elt of category.list){

				if(elt.active){
					list.actives.push(elt.id);
				}else{
					list.inactives.push(elt.id);
				}
			}
		}

		let info = {
			list: list
		};

		ProgressiveRequest({
			url: getServer()+'/api/front/account',
			method: 'post',
			data: {
				action: 'setKeywordsContent',
				session: session,
				list: info.list
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);
				
				if(data.saved){
					if(that.props.validate){
						that.props.validate();
					}
					
				}

			},
			received: function(){
				that.setState({saving: false});
				console.log('done');
			}
		});

		if(!that.props.editOnly){
			that.setState({displayMod: 'view'});
		}
	}

	changeActivation(item){
		let that = this;

		let nwList = [];

		for(let category of that.state.list){

			let nwCategoryList = [];
			for(let elt of category.list){
				let nwElt = {...elt};
				
				if(nwElt.id == item.id){
					nwElt.active = !nwElt.active;
				}
	
				nwCategoryList.push(nwElt);
			}

			let nwCategory = {...category};

			nwCategory.list = nwCategoryList;

			nwList.push(nwCategory);
		}

		that.setState({list: nwList});
	}

	render() {
		let that = this;
		
		if(that.state.displayMod == 'edit'){

			return (
				<div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<h2 class="h5 d-flex align-items-center mb-0">
							<img class="d-inline-block w-6 h-6 me-2" src="icons/Parcours Client_Terminal_Neptune.svg" /><span>Votre business</span>
							</h2>
							<button type="button" class="btn arrowb text-white rounded-pill d-flex align-items-center btn-sm px-4"  onClick={()=>{that.submit();}}>Valider</button>
						</div>
						<div class="card shadow edit">
							<div class="card-body">
								<div>
									{that.state.list.map((category)=>{
										return <div class="my-2">
											<div class="h5">{category.name}</div>
											<div>
												{category.list.map((item)=>{
													return <button type="button" class={"btn rounded-pill py-0 me-2 "+(item.active?'arrowb text-white':'bg-white borderb')} onClick={()=>{that.changeActivation(item)}}>{item.name}</button>;
												})}
											</div>
										</div>;
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
			
		}else{
			
			return (
				<div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<h2 class="h5 d-flex align-items-center mb-0 titleb">
								<img class="d-inline-block w-6 h-6 me-2" src="icons/Parcours Client_Terminal_Neptune.svg" />
								<span>Votre business</span>
							</h2>
							<button type="button" class="btn btn-outline-b rounded-pill d-flex align-items-center btn-sm px-4"  onClick={()=>{that.setState({displayMod: 'edit'});}}>Modifier</button>
						</div>
						<div class="card shadow">
							<div class="card-body">
								<div>
									{that.state.list.map((category)=>{
										return <div class="my-2">
											<div class="h5">{category.name}</div>
											<div>
											{category.list.map((item)=>{
												if(item.active){
													return <span class={"badge rounded-pill me-2 bg-light text-dark"}>{item.name}</span>;
												}
												return <></>;
											})}
											</div>
										</div>;
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}